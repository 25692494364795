import type { Theme } from "@wind/ui/theme";

export const windmillTheme: Theme = {
  colors: {
    accent: "#17181c",
    background: {
      base: "#f5f7fa",
      surface: "#ffffff",
      surfaceMuted: "#f1f5f9",
    },
    text: {
      primary: "#0f172a",
      secondary: "#334155",
      tertiary: "#64748b",
      inverse: "#f8fafc",
    },
    border: {
      default: "#e2e8f0",
    },
    sidebar: {
      background: "#ffffff",
      foreground: "#334155",
      primary: "#f1f5f9",
      primaryForeground: "#0f172a",
      accent: "#f1f5f9",
      accentForeground: "#0f172a",
      border: "#cbd5e1",
      ring: "#3b82f6",
    },
  },
  radius: 0.2,
  borderWidth: 1,
};

export const adminTheme: Theme = {
  colors: {
    accent: "#17181c",
    background: {
      base: "#f5f7fa",
      surface: "#ffffff",
      surfaceMuted: "#f1f5f9",
    },
    text: {
      primary: "#0f172a",
      secondary: "#334155",
      tertiary: "#64748b",
      inverse: "#f8fafc",
    },
    border: {
      default: "#e2e8f0",
    },
    sidebar: {
      background: "#e2e8f0",
      foreground: "#334155",
      primary: "#cdd5df",
      primaryForeground: "#0f172a",
      accent: "#ccd3d9",
      accentForeground: "#0f172a",
      border: "#8f9bab",
      ring: "#3b82f6",
    },
  },
  radius: 0.2,
  borderWidth: 1,
};

export const defaultTheme = windmillTheme;
