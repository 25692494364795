import objectPath from "object-path";
import { useEffect, type CSSProperties, type ReactNode } from "react";
import { hex } from "wcag-contrast";

import ColorUtil from "../util/ColorUtil.js";

// Type for hex color
type HexColor = string;

interface ThemeColors {
  accent: HexColor;
  background: {
    base: HexColor;
    surface: HexColor;
    surfaceMuted?: HexColor;
    accent?: HexColor;
    accentMuted?: HexColor;
    accentDark?: HexColor;
    error?: HexColor;
    errorAccent?: HexColor;
  };
  text: {
    primary: HexColor;
    secondary?: HexColor;
    tertiary?: HexColor;
    accent?: HexColor;
    inverse: HexColor;
    accentInverse?: HexColor;
    error?: HexColor;
    errorAccentInverse?: HexColor;
  };
  border: {
    default: HexColor;
    accent?: HexColor;
    muted?: HexColor;
    error?: HexColor;
  };
  sidebar: {
    background?: HexColor;
    foreground?: HexColor;
    primary?: HexColor;
    primaryForeground?: HexColor;
    accent?: HexColor;
    accentForeground?: HexColor;
    border?: HexColor;
    ring?: HexColor;
  };
}

export type Theme = {
  className?: string;
  colors: ThemeColors;
  radius?: number;
  borderWidth?: number;
};

const cssVariableNames = {
  "--bg-base": "background.base",
  "--bg-surface": "background.surface",
  "--bg-surface-muted": "background.surfaceMuted",
  "--bg-accent": "background.accent",
  "--bg-accent-dark": "background.accentDark",
  "--bg-border": "border.default",
  "--bg-error": "background.error",
  "--bg-error-accent": "background.errorAccent",
  "--bg-accent-inverse": "text.accentInverse",

  "--text-primary": "text.primary",
  "--text-secondary": "text.secondary",
  "--text-tertiary": "text.tertiary",
  "--text-accent": "text.accent",
  "--text-inverse": "text.inverse",
  "--text-accent-inverse": "text.accentInverse",
  "--text-error": "text.error",
  "--text-error-accent-inverse": "text.errorAccentInverse",

  "--border-default": "border.default",
  "--border-accent": "border.accent",
  "--border-muted": "border.muted",
  "--border-error": "border.error",
  "--border-accent-inverse": "text.accentInverse",

  // Sidebar
  "--sidebar-background": "sidebar.background",
  "--sidebar-foreground": "sidebar.foreground",
  "--sidebar-primary": "sidebar.primary",
  "--sidebar-primary-foreground": "sidebar.primaryForeground",
  "--sidebar-accent": "sidebar.accent",
  "--sidebar-accent-foreground": "sidebar.accentForeground",
  "--sidebar-border": "sidebar.border",
  "--sidebar-ring": "sidebar.ring",
};

export const generateThemeStyleObject = (theme: Theme) => {
  const cssVariables: Record<string, string> = {};

  const { colors, radius = 0.2, borderWidth = 1 } = theme;
  // Fill in accent colors
  if (!colors.text.accent) {
    colors.text.accent = colors.accent;
  }
  if (!colors.background.accent) {
    colors.background.accent = colors.accent;
  }
  if (!colors.border.accent) {
    colors.border.accent = colors.accent;
  }

  if (!colors.background.accentDark) {
    colors.background.accentDark = ColorUtil.blendHexes(colors.background.accent, "#000000", 0.1);
  }

  // Set colors programtically if they are missing
  if (!colors.text.accentInverse) {
    const backgroundBaseContrast = hex(colors.background.base, colors.background.accent!);
    const textBaseContrast = hex(colors.text.primary, colors.background.accent!);

    const accentInverse =
      backgroundBaseContrast > textBaseContrast ? colors.background.base : colors.text.primary;

    colors.text.accentInverse = accentInverse;
  }

  if (!colors.text.secondary) {
    colors.text.secondary = ColorUtil.blendHexes(colors.text.primary, colors.background.base, 0.6);
  }

  if (!colors.text.tertiary) {
    colors.text.tertiary = ColorUtil.blendHexes(colors.text.primary, colors.background.base, 0.4);
  }

  if (!colors.border.muted) {
    colors.border.muted = ColorUtil.blendHexes(colors.border.default, colors.background.base);
  }

  // Generate Surface Muted
  if (!colors.background.surfaceMuted) {
    colors.background.surfaceMuted = ColorUtil.blendHexes(
      colors.background.surface,
      colors.background.base
    );
  }

  // Genreate Accent Muted
  if (!colors.background.accentMuted) {
    colors.background.accentMuted = ColorUtil.blendHexes(
      colors.background.accent!,
      colors.background.base
    );
  }

  // Generate a set of reds to use for error messages
  const darkestRed = "#450a0a";
  const darkRed = "##991b1b";
  const lightRed = "#fca5a5";
  const lightestRed = "#fee2e2";

  // Compare darkest red and lightest red to base
  const darkestRedContrast = hex(colors.background.base, darkestRed);
  const lightestRedContrast = hex(colors.background.base, lightestRed);

  // If darkest red has more contrast, use it as text color
  if (darkestRedContrast > lightestRedContrast) {
    colors.text.error = darkestRed;
    colors.background.error = lightestRed;
    colors.border.error = lightRed;
    colors.background.errorAccent = darkRed;
    colors.text.errorAccentInverse = lightestRed;
  } else {
    colors.text.error = lightestRed;
    colors.background.error = darkestRed;
    colors.border.error = darkRed;
    colors.background.errorAccent = lightRed;
    colors.text.errorAccentInverse = darkestRed;
  }

  // Replace strings with colors
  const styles: Record<string, string> = Object.entries(cssVariableNames).reduce(
    (acc, [cssVar, themePath]) => {
      const value = objectPath.get(colors, themePath);
      return { ...acc, [cssVar]: value };
    },
    {}
  );

  Object.entries(styles).forEach(([cssVar, value]) => {
    // Get rgb from hex
    const rgb = value.match(/[A-Za-z0-9]{2}/g)?.map((v) => parseInt(v, 16));
    // Set css variable in format r g b
    cssVariables[cssVar] = rgb!.join(" ");
  });

  // Create border radius css variables
  const borderRadiuses = {
    none: 0,
    sm: (0.125 * radius) / 0.25,
    DEFAULT: (0.25 * radius) / 0.25,
    md: (0.375 * radius) / 0.25,
    lg: (0.5 * radius) / 0.25,
    full: 9999,
  };

  const borderWidths = {
    DEFAULT: borderWidth,
  };

  Object.entries(borderRadiuses).forEach(([key, value]) => {
    cssVariables[`--radius-${key}`] = `${value}rem`;
  });

  Object.entries(borderWidths).forEach(([key, value]) => {
    cssVariables[`--border-width-${key}`] = `${value}px`;
  });

  return cssVariables as CSSProperties;
};

export const generateThemeStyleString = (theme: Theme) => {
  const style = generateThemeStyleObject(theme);
  return Object.entries(style)
    .map(([key, value]) => `${key}: ${value};`)
    .join(" ");
};

/**
 * Wraps the inner children in a div with the theme applied as css variables
 *
 * @param param0
 * @returns
 */
export const ThemeWrapper = ({ theme, children }: { theme: Theme; children: ReactNode }) => {
  const style = generateThemeStyleObject(theme);

  return <div style={style}>{children}</div>;
};

/**
 * Applies the theme as css variables to the document root. This will apply it globally and should
 * generally only be used once
 *
 * @param theme
 */
export const useGlobalTheme = (theme: Theme | undefined, enabled = true) => {
  useEffect(() => {
    if (enabled && theme) {
      const style = generateThemeStyleObject(theme);
      // Puth css vars onto document root
      Object.entries(style).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value);
      });
    }
  }, [JSON.stringify(theme), enabled]);
};
