import { normal } from "color-blend";

import { InternalError } from "@wind/errors";

type HexColor = `#${string}` | string;

/**
 * ColorUtil is a utility class for color manipulation.
 *
 * @author @Mxs2019
 */
class ColorUtil {
  /**
   * Convert hex color to {r: number, g: number, b: number} object
   * r g b should be between 0 and 255
   */
  static hexToRgba(hex: HexColor, alpha = 1) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
      throw new InternalError(`Invalid hex color: ${hex}`);
    }

    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: alpha,
    };
  }

  /**
   * Convert rgba object to hex string (e.g. #ffffff00)
   * r, g, b are between 0 and 255; a is between 0 and 1
   */
  static rgbaToHex(rgba: { r: number; g: number; b: number; a: number }) {
    const { r, g, b, a } = rgba;
    const rHex = Math.round(r).toString(16).padStart(2, "0");
    const gHex = Math.round(g).toString(16).padStart(2, "0");
    const bHex = Math.round(b).toString(16).padStart(2, "0");
    const aHex = Math.round(a * 255)
      .toString(16)
      .padStart(2, "0");

    return `#${rHex}${gHex}${bHex}${aHex}`;
  }

  /**
   * Blend two hex colors.
   * @param top - The top color in the gradient.
   * @param bottom - The bottom color in the gradient.
   * @param alpha - The alpha value for the top color.
   * @returns The blended color in hex format.
   */
  static blendHexes(top: HexColor, bottom: HexColor, alpha = 0.5) {
    const topRgb = ColorUtil.hexToRgba(top, alpha);
    const bottomRgb = ColorUtil.hexToRgba(bottom, 1);
    return ColorUtil.rgbaToHex(normal(bottomRgb, topRgb));
  }

  /**
   * Gets blended color from an array of colors. Value is between 0 and 1.
   * Use the blend factor to get the color from the gradient.
   */
  static interpolateColorFromGradient(colors: HexColor[], value: number) {
    if (value < 0 || value > 1) {
      throw new InternalError("Value must be between 0 and 1");
    }

    if (colors.length < 2) {
      throw new InternalError("At least two colors are required for interpolation");
    }

    const segments = colors.length - 1;
    const segment = Math.min(Math.floor(value * segments), segments - 1);
    const segmentValue = value * segments - segment;

    const lowerColor = colors[segment];
    const upperColor = colors[segment + 1];

    return ColorUtil.blendHexes(upperColor, lowerColor, segmentValue);
  }
}

export default ColorUtil;
